import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Info, clsx } from "@gemlightbox/core-kit";
import { useStores } from "src/hooks";
import { DeviceMetaDataModel, MediaTypeKeys } from "src/models";
import styles from "./media-tags.module.css";
import { ReactComponent as AutoPhotographyTipsSVG } from "src/external-ts/assets/images/camera/auto-photography-tips.svg";
import { ReactComponent as AiRetouchSVG } from "src/external-ts/assets/images/edit-media/ai-retouch-icon.svg";
import { ReactComponent as RemoveBackgroundSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";
import { ReactComponent as DustRemoveSVG } from "src/external-ts/assets/images/edit-media/dust-remove-icon.svg";
import { ReactComponent as ModelImageSVG } from "src/external-ts/assets/images/edit-media/model-image-icon.svg";
import { ReactComponent as EclipseSVG } from "src/external-ts/assets/images/edit-media/eclipse-icon.svg";
import { ReactComponent as TransparentBGSVG } from "src/external-ts/assets/images/edit-media/transparent-bg-icon.svg";

import { MediaInfo } from "../media-info";

const useLongPress = (handlers?: EventHandlers) => {
  const timerRef = useRef<number | null>(null);

  const handleStart = () => {
    timerRef.current = window.setTimeout(() => {
      handlers?.onStart?.();
    }, handlers?.longPressTime ?? 500);
  };

  const handleEnd = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    handlers?.onEnd?.();
  };

  return { handleStart, handleEnd };
};

type EventHandlers = {
  onStart?: () => void;
  onEnd?: () => void;
  longPressTime?: number;
};

type MediaTagsProps = {
  metaData?: DeviceMetaDataModel;
  mediaType?: MediaTypeKeys;
  isShowInfo?: boolean;
  className?: string;
  isCreatedByMobile?: boolean;
  events?: {
    transparentBackground?: EventHandlers;
  };
};

export const MediaTags: React.FC<MediaTagsProps> = observer(
  ({ metaData, mediaType, isShowInfo, className = "", isCreatedByMobile, events }) => {
    const { localeStore } = useStores();
    const transparentBackgroundHandlers = useLongPress(events?.transparentBackground);

    return (
      <div className={clsx(styles.mediaTags, className)}>
        {mediaType === "blink" && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              toolTipClassName={styles.blockTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={4}
              icon={ModelImageSVG}
              size={20}
            >
              {isCreatedByMobile
                ? localeStore.t('media["media-tags"]["model-image"]')
                : localeStore.t('media["media-tags"]["model-image-editable"]')}
            </Info>
          </div>
        )}
        {metaData?.isTransparentBackground && (
          <div
            className={styles.mediaTagWrapper}
            onMouseDown={transparentBackgroundHandlers.handleStart}
            onMouseUp={transparentBackgroundHandlers.handleEnd}
            onTouchStart={transparentBackgroundHandlers.handleStart}
            onTouchEnd={transparentBackgroundHandlers.handleEnd}
          >
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={4}
              icon={TransparentBGSVG}
              size={18}
            >
              {localeStore.t('media["media-tags"]["transparent-background"]')}
            </Info>
          </div>
        )}
        {metaData?.isAiBackgroundRemoval && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={4}
              icon={RemoveBackgroundSVG}
              size={20}
            >
              {localeStore.t('media["media-tags"]["removed-background"]')}
            </Info>
          </div>
        )}
        {metaData?.isAiRetouch && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={2}
              icon={AiRetouchSVG}
            >
              {localeStore.t('media["media-tags"]["ai-retouched"]')}
            </Info>
          </div>
        )}
        {metaData?.isAiRemoveDust && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={2}
              icon={DustRemoveSVG}
            >
              {localeStore.t('media["media-tags"]["removed-dust"]')}
            </Info>
          </div>
        )}
        {metaData?.isEclipseMode && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={2}
              icon={EclipseSVG}
            >
              {localeStore.t('media["media-tags"]["eclipse-mode"]')}
            </Info>
          </div>
        )}
        {isShowInfo && (
          <div className={styles.mediaTagWrapper}>
            <Info
              className={styles.infoTooltip}
              appearance="secondary"
              position="top"
              withAngle
              offsetY={6}
              icon={AutoPhotographyTipsSVG}
              size={16}
            >
              <MediaInfo className={styles.mediaDetails} metaData={metaData} />
            </Info>
          </div>
        )}
      </div>
    );
  },
);
